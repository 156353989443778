export const subscribeUseStatusConfig = {
  true: { label: '已選用', value: true, tagType: 'action' },
  false: { label: '未選用', value: false, tagType: 'info' },
}

export const subscribeContractSelectedConfig = {
  true: { label: '已設定合約', value: true, tagType: 'action' },
  false: { label: '無', value: false, tagType: 'info' },
}

export const createSubscribePlanTypeConfig = {
  create: { label: '新增空白的訂閱方案', value: 'create' },
  copy: { label: '複製現有的訂閱方案', value: 'copy' },
}

export const subscribeDurationTypeConfig = {
  monthly: { label: '按月份計算期數', value: 'monthly' },
  manual: { label: '自定義每期天數', value: 'manual' },
}

export const subscribePaymentTypeConfig = {
  periods: { label: '按期數均分', value: 'periods' },
//   manual: { label: '自定義每期扣款金額', value: 'manual' },
}

export const subscribePaymentDependTypeConfig = {
  subscribe: { label: '依會員訂閱日', value: 'subscribe' },
//   specificDay: { label: '依指定日期', value: 'specificDay' },
}

export const subscribePaymentTimeTypeConfig = {
  beforeUsed: { label: '使用前付費 ( 訂閱當下立即扣款 )', value: 'beforeUsed' },
  afterUsed: { label: '使用後付費', value: 'afterUsed' },
}

export const subscribeOrderStatusConfig = {
  ongoing: { label: '進行中', value: 'ongoing', tagType: 'action' },
  terminated: { label: '已結束', value: 'terminated', tagType: 'info' },
  paused: { label: '暫停中', value: 'paused', tagType: 'warning' },
}

export const subscribeOrderPaymentFailStatusConfig = {
  true: { label: '有扣款失敗紀錄', value: true },
  false: { label: '無扣款失敗紀錄', value: false },
}

export const subscribeOrderPaymentStatusConfig = {
  wait: { label: '待扣款', value: 'wait', tagType: 'warning' },
  paid: { label: '已扣款', value: 'paid', tagType: 'action' },
  refund: { label: '已退款', value: 'refund', tagType: 'info' },
  wait_refund: { label: '待退款', value: 'wait_refund', tagType: 'warning' },
  fail: { label: '扣款失敗', value: 'fail', tagType: 'danger' },
  cancel: { label: '已取消', value: 'cancel', tagType: 'info' },
}

export const subscribeOrderPaymentOriginConfig = {
  system: { label: '自動扣款', value: 'system' },
  manual: { label: '後台扣款', value: 'manual' },
}
export const subscribeOrderPaymentOriginRefundConfig = {
  system: { label: '自動退款', value: 'system' },
  manual: { label: '後台退款', value: 'manual' },
}

export const subscribeOrderInfoEditTypeConfig = {
  stopSubscribe: { label: '暫停訂閱', value: 'stopSubscribe' },
  cancelSubscribe: { label: '取消訂閱', value: 'cancelSubscribe' },
  updateEndDate: { label: '變更終止日', value: 'updateEndDate' },
}

export const orderOrigins = {
  client: { label: '會員', value: 'client' },
  admin: { label: '後台', value: 'admin' },
}

export const systemContract = `
<p>甲方：訂閱者</p>
<p>乙方：{請填寫公司名稱} </p><br/>
<p>茲就甲方就乙方所提供之軟體系統服務有合作意願，雙方經合意簽訂合約書如下：</p><br/>
<p><strong>第一條 合作方式</strong></p>
<ol>
     <li>乙方應提供__________軟體服務系統，包含下列
          <ol>
               <li>會員註冊系統</li>
               <li>會員抽獎行銷模組</li>
               <li>預約系統</li>
               <li>預約通知</li>
               <li>預約行事曆通知-服務人員,消費者</li>
               <li>預約付訂金</li>
               <li>................</li>
          </ol>
     </li>
     <li>乙方應協助提供提供甲方________系統方案包含
          <ol>
               <li> </li>
               <li> </li>
          </ol>
     </li>
</ol>
<p><strong>第二條 合約期間</strong></p>
<ol>
     <li>本合約期間自民國____年__月__日起至___年__月__日止。</li>
     <li>
          <ul>
               <li>□ 本合約到期後，不自動續約。</li>
               <li>□ 本合約到期後，將自動續約。</li>
               <li>□ 本合約到期後，不自動續約。雙方應另行更新合約</li>
          </ul>
     </li>
</ol>
<p><strong>第三條 費用及付款辦法</strong></p>
<ol>
     <li>甲方同意以下列方案進行訂閱乙方提供之服務。
          <ol>
              <li>□ 一般方案：甲方於簽訂本合約時應同時付款。</li>
              <li>□ 體驗方案：甲方於簽訂本合約時應提供信用卡相關資料進行綁卡，自體驗期間結束後，第二期開始付款。</li>
          </ol>
     </li>
     <li>訂閱服務金額：
          <ol>
              <li>□ 甲方之每月訂閱費用為新台幣＿＿＿＿＿元（含稅）。</li>
          </ol>
     </li>
     <li>付費週期：
          <ol>
              <li>□ 甲方自本合約簽訂時起，每月進行扣款。</li>
              <li>□ 甲方自本合約簽訂時起，每月5日進行扣款</li>
          </ol>
     </li>
     <li>付款方式：
        乙方同意甲方得以下列方式進行付款
          <ol>
               <li>永豐儲值金價保</li>
               <li>TapPay 線上金流</li>
          </ol>
     </li>
</ol>
<p><strong>第四條 智慧財產權約定</strong></p>
<ol>
     <li>智慧財產權之歸屬：
          <ol>
               <li>甲方同意關於乙方提供軟體服務系統之其智慧財產權（包含但不限於著作財產權等）（包含但不限於程式設計專案暨其程式原始碼等，相關之軟體、程式、圖片暨其程式原始碼等），均歸屬乙方所有。乙方於本合作期間內授權甲方合法使用之權利。</li>
               <li>本合約僅係非專屬授權甲方使用本軟體，而非販售賣斷。</li>
               <li>除本合約另有約定外，甲方不因本合約而取得本公司任何智慧財產權。</li>
          </ol>
     </li>
     <li>智慧財產權之保證：
          <ol>
               <li>甲方保證其所提供予乙方與本專案相關之文字及圖片資料皆未違反著作權法，若有違反著作權之爭議，甲方需賠償乙方所有損害，並且相關法律責任由甲方負責。</li>
               <li>乙方保證其提供之軟體服務系統及其來源（包含但不限於程式原始碼、攝影著作、美術著作、圖片等）均屬合法正當且未侵害任何第三人合法享有之權利(包含但不限於商標權、專利權、著作財產權等)，若有相關爭議，由乙方負相關法律責任。</li>
          </ol>
     </li>
</ol>
<p><strong>第五條 軟體維修與升級及責任限制</strong></p>
<ol>
     <li>本軟體是依「現狀」提供甲方使用，並不附帶任何明示或默示之保證，甲方須自擔風險。在適用法律允許的最大範圍內，乙方就任何事宜，包括但不限於不侵害第三方權利、適售性、完整性、品質滿意度、準確性、效能或任何特定用途之適用性，不論依成文法、普通法、慣例、使用常規或其他規範，概不作出任何明示或默示之保證條件、陳述或條款。</li>
     <li>服務之停止、中斷乙方將依一般合理之技術及方式，維持系統及服務之正常運作。但於以下各項情況時，乙方有權可以停止、中斷提供本服務：(1).乙方電子通信設備進行必要之保養及施工時。(2).發生突發性之電子通信設備故障時。(3).乙方申請之電子通信服務被停止，無法提供服務時。(4).由於天災等不可抗力之因素或其他不可歸責於乙方致使乙方無法提供服務時。</li>
     <li>乙方對於因為使用本軟體(乙方已盡商業合理努力對本軟體進行編寫、維護、更新以及除BUG之版本)、不當使用本軟體或不能使用本軟體而引起任何衍生性、特別、附隨、間接損害(包括但不限於營業利潤之損失、業務中斷、商業資訊之損失或其他金錢損失)不負任何損害賠償責任。</li>
     <li>即使乙方已告知前述損害之可能發生，除乙方故意行為造成本軟體有瑕疵外，乙方亦無需負損害賠償責任。</li>
     <li>由於本軟體的開發是持續性的，乙方將繼續更新、改善本軟體。因此乙方可能對本軟體之功能做各種變動及更新，乙方於進行變動或更新時，將告知甲方，但甲方不得拒絕，甲方不得據此向乙方要求任何損害賠償。</li>
     <li>依本合約書乙方所負之責任，僅限於甲方對本軟體於本合約時間所付之實際價款。</li>
</ol>
<p><strong>第六條 保密約定</strong></p>
<p>雙方對因合約而知悉或持有之他方之商業機密，應負保密義務，且未經他方書面同意前，不得將其為合約目的外之使用或洩漏予第三人，接受機密資訊之一方並應採取必要措施，防止該機密資訊被竊、洩漏。；（三）及/或因未盡注意或忠誠義務而未經授權揭露機密商業資訊。若甲方受有損害時，甲方並得向乙方請求損害賠償。</p>
<p><strong>第七條 終止與解除</strong></p>
<p>於下列情形，任一方得單方面終止本合約：</p>
<ol>
     <li>甲方得隨時終止合約。</li>
     <li>若甲方於訂閱服務當期 1/3 前取消訂閱，乙方同意由系統直接退還當期訂閱全額金額。</li>
     <li>若甲方於訂閱服務當期 1/3 後取消訂閱，乙方無須還當期款項。</li>
</ol>
<p><strong>第八條 爭議處理</strong></p>
<p>若因本合約書未能履行或履行不完全所生之爭議，雙方同意先本誠信原則協商之，如需涉訟雙方同意以台灣台北地方法院為第一審管轄法院。</p>
<p><strong>第九條 其他</strong></p>
<ol>
     <li>本合約書未約定事項，雙方得以書面方式另行約定，修正時亦同。</li>
     <li>本合約正本壹式貳份，由甲、乙方各執正本壹份為憑。</li>
</ol>`

export const gymContract = `
<p>甲方：訂閱者</p>
<p>乙方：{請填寫公司名稱} </p><br/>
<p>茲為甲方為使用乙方所提供之健身房教學課程及相關服務，雙方經合意簽訂合約書如下：</p><br/>
<p><strong>第一條 服務課程</strong></p>
<ol>
     <li>乙方應提供__________健身房課程訂閱服務，包含下列
          <ol>
               <li>會員註冊系統</li>
               <li>會員抽獎行銷模組</li>
               <li>預約系統</li>
               <li>預約通知</li>
               <li>預約行事曆通知-服務人員,消費者</li>
               <li>預約付訂金</li>
               <li>................</li>
          </ol>
     </li>
     <li>乙方應提供甲方________方案包含：
          <ol>
               <li>健身房會籍
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>團體課程
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>教練課程
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
          </ol>
     </li>
</ol>
<p><strong>第二條 合約期間</strong></p>
<ol>
     <li>本合約期間自民國____年__月__日起至___年__月__日止。</li>
     <li>
          <ul>
               <li>□ 本合約到期後，不自動續約。</li>
               <li>□ 本合約到期後，將自動續約。</li>
               <li>□ 本合約到期後，不自動續約。雙方應另行更新合約</li>
          </ul>
     </li>
</ol>
<p><strong>第三條 費用及付款辦法</strong></p>
<ol>
     <li>甲方同意以下列方案進行訂閱乙方提供之服務。
          <ol>
              <li>□ 一般方案：甲方於簽訂本合約時應同時付款。</li>
              <li>□ 體驗方案：甲方於簽訂本合約時應提供信用卡相關資料進行綁卡，自體驗期間結束後，第二期開始付款。</li>
          </ol>
     </li>
     <li>訂閱服務金額：
          <ol>
              <li>□ 甲方之每月訂閱費用為新台幣＿＿＿＿＿元（含稅）。</li>
          </ol>
     </li>
     <li>付費週期：
          <ol>
              <li>□ 甲方自本合約簽訂時起，每月進行扣款。</li>
              <li>□ 甲方自本合約簽訂時起，每月5日進行扣款</li>
          </ol>
     </li>
     <li>付款方式：
        乙方同意甲方得以下列方式進行付款
          <ol>
               <li>永豐儲值金價保</li>
               <li>TapPay 線上金流</li>
          </ol>
     </li>
</ol>
<p><strong>第四條 請假及補課規範</strong></p>
<ol>
     <li>甲方如因個人因素需暫停課程，應提前 ______ 日請假並通知乙方，請假完成後，乙方將安排補課時間，甲方需於乙方所安排之時間內完成補課。</li>
     <li>甲方每月可請假次數為 ______ 次，超過次數後的請假視為缺課，不予補課。</li>
     <li>若甲方無法在補課期限內完成補課，則視為放棄該次課程，乙方不再另行安排補課。</li>
</ol>
<p><strong>第五條 智慧財產權及保密約定</strong></p>
<ol>
     <li>乙方知悉甲方提供之課程教材等資訊皆為甲方營業機密，非經甲方書面事先許可，乙方不得以擅自洩漏、複印、重製等相關方式侵害甲方著作權、營業秘密。</li>
     <li>乙方如違反本條規定，除應賠償甲方一切損失外（包含但不限於，律師費用、訴訟費用），另應給付甲方懲罰性違約金。</li>
     <li>本條規定於本合約期間屆滿後或因任何原因終止後仍繼續有效。</li>
</ol>
<p><strong>第六條 終止與解除</strong></p>
<p>於下列情形，任一方得單方面終止本合約：</p>
<ol>
     <li>甲方得隨時終止合約。</li>
     <li>若甲方於訂閱服務當期 1/3 前取消訂閱，乙方同意由系統直接退還當期訂閱全額金額。</li>
     <li>若甲方於訂閱服務當期 1/3 後取消訂閱，乙方無須還當期款項。</li>
</ol>
<p><strong>第七條 爭議處理</strong></p>
<p>若因本合約書未能履行或履行不完全所生之爭議，雙方同意先本誠信原則協商之，如需涉訟雙方同意以台灣台北地方法院為第一審管轄法院。</p>
<p><strong>第八條 其他</strong></p>
<ol>
     <li>本合約書未約定事項，雙方得以書面方式另行約定，修正時亦同。</li>
     <li>本合約正本壹式貳份，由甲、乙方各執正本壹份為憑。</li>
</ol>`

export const productContract = `
<p>甲方：訂閱者</p>
<p>乙方：{請填寫公司名稱} </p><br/>
<p>茲就商品配送甲方同意使用乙方提供之服務，為明確規範雙方之權利義務關係，經合意簽訂合約書如下：</p><br/>
<p><strong>第一條 服務內容</strong></p>
<p>乙方協助甲方配送蔬菜、水果、羊奶…(商品說明)</p>
<ol>
     <li>
          <ol>
               <li>配送規格及品項：
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>訂購數量：
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>訂購方式：
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>配送時間：
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
               <li>配送地點：依甲方指定地點送達。
                    <ol>
                         <li></li>
                         <li></li>
                         <li></li>
                    </ol>
               </li>
          </ol>
     </li>
</ol>
<p><strong>第二條 合約期間</strong></p>
<ol>
     <li>本合約期間自民國____年__月__日起至___年__月__日止。</li>
     <li>
          <ul>
               <li>□ 本合約到期後，不自動續約。</li>
               <li>□ 本合約到期後，將自動續約。</li>
               <li>□ 本合約到期後，不自動續約。雙方應另行更新合約</li>
          </ul>
     </li>
</ol>
<p><strong>第三條 費用及付款辦法</strong></p>
<ol>
     <li>甲方同意以下列方案進行訂閱乙方提供之服務。
          <ol>
              <li>□ 一般方案：甲方於簽訂本合約時應同時付款。</li>
              <li>□ 體驗方案：甲方於簽訂本合約時應提供信用卡相關資料進行綁卡，自體驗期間結束後，第二期開始付款。</li>
          </ol>
     </li>
     <li>訂閱服務金額：
          <ol>
              <li>□ 甲方之每月訂閱費用為新台幣＿＿＿＿＿元（含稅）。</li>
          </ol>
     </li>
     <li>付費週期：
          <ol>
              <li>□ 甲方自本合約簽訂時起，每月進行扣款。</li>
              <li>□ 甲方自本合約簽訂時起，每月5日進行扣款</li>
          </ol>
     </li>
     <li>付款方式：
        乙方同意甲方得以下列方式進行付款
          <ol>
               <li>永豐儲值金價保</li>
               <li>TapPay 線上金流</li>
          </ol>
     </li>
</ol>
<p><strong>第四條 個人資料安全</strong></p>
<ol>
     <li>甲方應為完全行為能力之自然人、或合法登記的法人或團體。甲方瀏覽造訪本網站或經註冊而使用本服務起，即視為已閱讀、暸解、並同意本使用條款所有條款。</li>
     <li>甲方同意於使用本服務時，所填寫之個人資料將由乙方依個人資料保護法及隱私權聲明予以保護及利用，並同意以方得以電子郵件、簡訊或其他方式提供各項服務與優惠訊息。</li>
     <li>甲方應負責確保使用者帳號的安全，包含但不限於採取適當的安全措施以保護其使用者名稱、密碼或其他用以使用本服務之商品代碼與密碼、電子郵件。甲方同意對任何經輸入正確使用者帳號與密碼與通過其他乙方設置的驗證措施後的使用者帳號一切行為負擔法律和財務責任。</li>
     <li>甲方不得將使用者帳號、使用者名稱、密碼與任何未經授權之第三人共享，並應採取必要且合理的措施以及善良管理人之注意義務，確保使用者帳號不會在未經授權狀態下被使用。若甲方認為使用者帳號可能已遭任何第三人在未經授權的狀態下使用，或其使用者名稱和密碼可能已被第三人在未經同意下獲知，甲方應立即通知乙方，並配合本公司進行即時之安全防禦處置。</li>
</ol>
<p><strong>第五條 智慧財產權</strong></p>
<p>乙方之網站及相關通路所使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由乙方或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若甲方欲引用或轉載前述軟體、程式或網站內容，必須依法取得乙方或其他權利人的事前書面同意，如有違反，應對乙方或其他權利人負損害賠償責任（包括但不限於訴訟費用、律師費用及本公司商譽之損害等）。</p>
<p><strong>第六條 注意事項</strong></p>
<p>本商品符合「通訊交易解除權合理例外情事適用準則」第2條「易於腐敗、保存期限較短或解約時即將逾期」的情形，故不適用消保法第19條第1項規定七天鑑賞期的範圍內退貨說明。</p>
<p><strong>第七條 終止與解除</strong></p>
<p>於下列情形，任一方得單方面終止本合約：</p>
<ol>
     <li>甲方得隨時終止合約。</li>
     <li>若甲方於訂閱服務當期 1/3 前取消訂閱，乙方同意由系統直接退還當期訂閱全額金額。</li>
     <li>若甲方於訂閱服務當期 1/3 後取消訂閱，乙方無須還當期款項。</li>
</ol>
<p><strong>第八條 爭議處理</strong></p>
<p>若因本合約書未能履行或履行不完全所生之爭議，雙方同意先本誠信原則協商之，如需涉訟雙方同意以台灣台北地方法院為第一審管轄法院。</p>
<p><strong>第九條 其他</strong></p>
<ol>
     <li>本合約書未約定事項，雙方得以書面方式另行約定，修正時亦同。</li>
     <li>本合約正本壹式貳份，由甲、乙方各執正本壹份為憑。</li>
</ol>`

export const invoiceTypeConfig = {
  C: { label: '二聯式發票', value: 'C' },
  B: { label: '三聯式發票', value: 'B' },
}

export const invoiceStatusConfig = {
  pending: { label: '未開立', value: 'pending', tagType: 'info', disabled: true },
  issued: { label: '已開立', value: 'issued', tagType: 'action', disabled: true },
  markedVoided: { label: '標記作廢', value: 'markedVoided', tagType: 'danger', disabled: false },
  markedAllowance: { label: '標記折讓', value: 'markedAllowance', tagType: 'danger', disabled: false },
}

export const invoiceIssuedTypeConfig = {
  auto: { label: '扣款後系統自動開立', value: 'auto' },
  manual: { label: '手動開立發票', value: 'manual' },
}
